import styled from "styled-components";
import { Input } from "reactstrap";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#00c2d1" : 0,
    "&:hover": {
      backgroundColor: state.isSelected ? 0 : "#e1f6f7",
    },
  }),
  control: (base, state) => ({
    ...base,
    paddingLeft: "5px",
    boxShadow: state.isFocused ? "0 0 0 3px  rgb(209,241,243)" : 0,
    border: state.isFocused ? "1px solid rgb(103,233,244)" : base.border,
    "&:hover": {
      border: state.isFocused ? "1px solid  rgb(103,233,244)" : base.border,
    },
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: "1rem",
    color: "hsl(210,8.8%,31.4%)",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "1rem",
    fontFamily: "inherit",
    color: "rgb(108,117,125)",
    fontWeight: 400,
  }),
};

const errorStyles = {
  option: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    backgroundColor: state.isSelected ? "#00c2d1" : 0,
    "&:hover": {
      backgroundColor: state.isSelected ? 0 : "#e1f6f7",
    },
  }),
  control: (base, state) => ({
    ...base,
    paddingLeft: "5px",
    boxShadow: state.isFocused ? "0 0 0 3px  rgb(209,241,243)" : 0,
    border: "1px solid rgb(255,0,0)",
    "&:hover": {
      border: "1px solid rgb(255,0,0)",
    },
  }),
  singleValue: (base) => ({
    ...base,
    fontSize: "1rem",
    color: "hsl(210,8.8%,31.4%)",
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "1rem",
    fontFamily: "inherit",
    color: "rgb(108,117,125)",
    fontWeight: 400,
  }),
};

const Inputs = styled(Input)`
  width: 100%;
  height: 38px;
  background-color: transparent;
  border-radius: 4px;
  padding: 5px 5px 5px 30px;
  font-size: 1rem;
  top: 0;
  left: 0;
  z-index: 1;
`;

const ButtonDropdown = styled.span`
  .dropdown-toggle {
    display: flex;
    align-items: center;
  }

  .dropdown-toggle:after {
    margin-left: 0.5em;
    margin-top: -3px;
    padding: 3.1px;
  }

  a.dropdown-toggle:after {
    margin-top: -3px;
    padding: 3.1px;
  }

  button.dropdown-toggle:after {
    margin-top: -3px;
    padding: 3.1px;
  }
`;

const PhoneInput = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

// Get the current subdomain
const subdomain = window.location.hostname.split('.')[0];

// Modify the customStyles object based on the subdomain
if (subdomain === "unica") {
  customStyles.control = (base, state) => ({
    ...base,
    paddingLeft: "5px",
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(37, 120, 188, 0.25)" : 0,
    border: state.isFocused ? "1px solid #7bb6e5" : base.border,
    "&:hover": {
      border: state.isFocused ? "1px solid  #7bb6e5" : base.border,
    },
  });
} else if (subdomain === "lavabeam") {
  customStyles.control = (base, state) => ({
    ...base,
    paddingLeft: "5px",
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(253, 138, 8, 0.25)" : 0,
    border: state.isFocused ? "1px solid #fec686" : base.border,
    "&:hover": {
      border: state.isFocused ? "1px solid #fec686" : base.border,
    },
  });
} else if (subdomain === "vodafone") {
  customStyles.control = (base, state) => ({
    ...base,
    paddingLeft: "5px",
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(236, 27, 46, 0.25)" : 0,
    border: state.isFocused ? "1px solid #f6919a" : base.border,
    "&:hover": {
      border: state.isFocused ? "1px solid #f6919a" : base.border,
    },
  });
} else if (subdomain === "penguin") {
  customStyles.control = (base, state) => ({
    ...base,
    paddingLeft: "5px",
    boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(255, 102, 0, 0.25)" : 0,
    border: state.isFocused ? "1px solid #ffb380" : base.border,
    "&:hover": {
      border: state.isFocused ? "1px solid #ffb380" : base.border,
    },
  });
}

export { customStyles, Inputs, ButtonDropdown, PhoneInput, errorStyles };
